<template>
  <div>
    <div class="findlist">
      <div class="flex">
        <div class="select">
          <select>
            <option>调查表标题</option>
          </select>
        </div>
        <div><input type="text" v-model="title" /></div>
        <button type="button" class="btn" @click="search">搜索</button>
      </div>
    </div>
    <div class="bd">
      <ul class="newslist" style="margin-bottom:20px">
        <li v-for="item in searchs" :key="item.id">
          <div class="flex_item" @click="toDetail(item.id)"><a href="javascript:;" >{{item.title}}</a></div>
          <span>{{item.isEnd == 1 ? "已结束" : item.endTime}}</span>
        </li>
      </ul>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          background
          :page-sizes="[20, 40, 60, 80]"
          :page-size="size"
          layout="prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{
      searchs: [],
      title: "",
      page: 1,
      size: 20,
      total: 0,
    }
  },
  props:{
    columnId:{
      type:[String,Number],
      default:0
    },
    pid:{
      type:[String,Number],
      default:0
    }
  },
  mounted(){
    this.getSearchColumn();
  },
  methods:{
    async getSearchColumn() {
      let res = await this.$api.searchColumn({
        title: this.title,
        columnName: "客户调查列表",
        page: this.page,
        size: this.size,
      });
      console.log(res.data.data)
      this.searchs = res.data.data.data;
      this.total = res.data.data.totalPage;
    },
    handleSizeChange(val) {
      this.size = val;
      this.getSearchColumn();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getSearchColumn();
    },
    search() {
      this.getSearchColumn();
    },
    toDetail(id){
      this.$router.push(`/contents/${this.pid}/${this.columnId}/survey_${id}.html`)
    }
  },
}
</script>
<style>

</style>
