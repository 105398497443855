<template>
  <div>
    <div class="history-title">{{history.title}}</div>
    <div class="history-summary">{{history.summary}}</div>
    <div class="history-steps-box">
      <div v-for="(item,index) in history.steplist" :key="index">
        <div class="history-steps-item">
          <div class="history-years-box">
            <div class="history-years">{{item.year}}年</div>
          </div>
          <div :class="index<(history.steplist.length-1)?item.suorce?'history-desc-box1':'history-desc-box3':'history-desc-box2'">
            <div class="history-desc-title">{{item.name}}</div>
            <div class="history-desc-suorce">{{item.suorce}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//发展历程模型组件

export default {
  props:{
    id:{
      type:Number,
      default:0
    },
  },
  data(){
    return {
      articleDetail:'',
      name:'',
      history:{
        title:'中联认证中心隶属于国家中央直属大型科技企业——机械科学研究总院。',
        summary:'机械科学研究总院在长期开展行业技术推进与服务过程中，积累了丰富的经验，形成了雄厚的技术资源，组建了一批从事标准化研究、电工产品认证及机械产品安全认证和在我国第一批获得国家认可专项从事管理体系认证的专业部门和服务机构。',
        steplist:[
          {year:'2017',name:'增值更名“中联认证中心”（北京）有限公司',suorce:'中联认证中心（北京）有限公司现隶属于机械科学研究总院集团有限公司所属中机寰宇认证检验有限公司全资注册和管理，从事管理体系和产品认证的独立的第三方技术中介服务机构。'},
          {year:'2002',name:'增值更名“中联认证中心”（北京）有限公司',suorce:'中联认证中心（北京）有限公司现隶属于机械科学研究总院集团有限公司所属中机寰宇认证检验有限公司全资注册和管理，从事管理体系和产品认证的独立的第三方技术中介服务机构。'},
          {year:'2000',name:'增值更名“中联认证中心”（北京）有限公司',suorce:''},
          {year:'1997',name:'增值更名“中联认证中心”（北京）有限公司',suorce:''},
          {year:'1996',name:'增值更名“中联认证中心”（北京）有限公司',suorce:''},
          {year:'1992',name:'增值更名“中联认证中心”（北京）有限公司',suorce:''},
        ],
      },
    }
  },
  methods:{
  },
}
</script>

<style scoped>

</style>
