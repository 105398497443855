<template>
  <div class="m-news-pic">
    <div class="ul">
      <div v-for="(item,index) in dataList" :key="index"  class="news_item">
        <a @click="toDetail(item.id)">
          <img :src="$baseURL + item.imgUrl">
          <h3>
            <div class="pic-title">
              {{item.title}}
            </div>
          </h3>
        </a>
      </div>
    </div>
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "pictureNews",
  props:{
    id:{
      type:[String,Number],
      default:0
    },
    columnId:{
      type:[String,Number],
      default:0
    },
    pid:{
      type:[String,Number],
      default:0
    }
  },
  data(){
    return {
      dataList:[],
      pageNum: 1,
      pageSize: 9,
      total: 0,
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    async getList(){
      let res = await this.$api.getArticleListById({
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        id:this.id
      })
      this.dataList = res.data.data.list
      this.total = res.data.data.total
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    getText(str){
      let res = str.replace(/[^\u4e00-\u9fa5]/gi, "")
      if(res.length > 80){
        res = res.slice(0,80) + '...'
      }
      return res
    },
    toDetail(id){
      this.$router.push(`/contents/${this.pid}/${this.columnId}/${id}.html`)
    }
  }
}
</script>

<style scoped>
.ul{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}
.news_item{
  width: 30%;
  margin-top: 28px;
  margin-right: 30px;
}
.news_item img{
  width: 100%;
  height: 164px;
}
.m-news-pic  h3 {
  font-size: 14px;
  height: 54px;
  line-height: 54px;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
}
.news_item:nth-child(3n){
  margin-right: 0;
}
a:hover {
  color: #275caa;
}
.m-news-pic  a:hover {
  border: 1px solid #275caa;
}
.m-news-pic  a {
  padding: 3px 3px 0;
  display: block;
  background: #fff;
  border: 1px solid #cae4ff;
}
.pic-title{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 4px;
}
</style>
