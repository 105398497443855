<template>
  <div>
    <div class="banner-wrapper">
      <div class="banner_info" :style="`background: url(${bannerUrl}) center no-repeat;background-size: cover;`" v-if="leftData.length>0 && leftData[0]">
        <dl>
          <dt>{{leftData[0].name}}</dt>
          <dd>{{leftData[0].name_en}}</dd>
        </dl>
      </div>
    </div>
    <div class="infopage">
<!--      显示条件 是证书查询栏目，并且是中联-->
      <div class="container" v-if="$store.state.stations.name.includes('中联') && currentItem.name=='证书查询' ">
        <!--中联-->
        <zl_certification @zlQueryClick="queryClick"  />
      </div>
      <div class="container" v-if="!$store.state.stations.name.includes('中联')">
        <certification @queryClick="queryClick" />
      </div>
      <!--其他风格模型-->
      <div class="container clearfix" >
       <!-- 未查询的语句-->
       <template v-if="!showAnnounce">
         <!--左侧导航分类展示-->
         <template v-if="leftData.length > 0 && leftData[0].children.length>0 && leftData[0].children[0].children.length>0">
           <div class="left" v-if="(leftData.length > 0 && leftData[0].template != 'picture_column') || columnPid !=0 ">
             <div class="box" v-for="(item,index) in leftData[0].children" :key="item.id" >
               <dl v-if="index==0"  >
                 <dt>{{leftData[0].name}}</dt>
                 <dd>{{leftData[0].name_en}}</dd>
               </dl>
               <!--一级栏目是否展开 on展开 active高亮-->
               <!--<h3 :class="{on:item.id == id,active:cId == item.id}" @click="tabClick(item,index)">-->

               <!--如果有三级栏目，则展示三级子栏目内容-->
               <template v-if="item.children.length > 0 ">
                 <h3 :class="{on:(item.id == columnId || item.id ==  columnPid),mt:index==0}">
                   <a href="javascript:;" @click="navTo(item)">{{item.name}}</a>
                   <span><i></i></span>
                 </h3>
                 <ul>
                   <!--默认只有选中的栏目才展开，其他折叠-->
                   <template v-if="item.id == columnId || item.parent_id == pid || item.id ==  columnPid || (columnId == pid && index == 0)">
                     <!--如果栏目id相同则自动选中，或则不相同选中第一个-->
                     <li @click="navTo(val)" v-for="(val,i) in item.children" :key="val.id"
                         :class="{on:(columnId==val.id) || (columnId==item.id && i==0) || (columnId == pid && index == 0 && i==0)}" >
                       <a href="javascript:;">{{val.name}}</a>
                     </li>
                   </template>
                 </ul>
               </template>
             </div>
           </div>
         </template>
         <template v-else>
           <div class="left" >
             <div class="box" v-if="(leftData.length > 0 && leftData[0].template != 'picture_column' && leftData[0].children.length > 0) || columnPid !=0 ">
               <dl>
                 <dt class="pt">{{leftData[0].name}}</dt>
               </dl>
               <ul>
                 <li @click="navTo(val)" :class="{on:val.id == columnId || (columnId==pid && i ==0)}"
                     v-for="(val,i) in leftData[0].children" :key="val.id">
                   <a href="javascript:;">{{val.name}}</a>
                 </li>
               </ul>
             </div>

           </div>
         </template>
         <!--          图片展示栏目-->
         <div class="right right_lg" v-if="currentItem.template == 'picture_column'">

           <div class="box" >
             <pictureColumn :columns="leftData[0]"  :pid="pid"></pictureColumn>
           </div>

         </div>

         <div class="right right_lg" v-else-if="currentItem.template == 'common_list' && leftData[0].children.length==0">
           <div class="box" >
             <div class="title flex lg">
               <div class="flex_item"><b class="">{{currentItem.name=='证书查询'?'证书公告':currentItem.name}}</b></div>
               <div class="bread">
                 <a href="#">首页</a> > <a href="#" v-if="leftData.length>0">{{leftData[0].name}}</a>
               </div>
             </div>
             <!--这里特殊处理 可能展示证书公告的内容-->
             <template v-if="currentItem.name == '证书查询'">
                <certAdv></certAdv>
             </template>
             <commonList v-else :column-id="columnId" :pid="pid" :id="currentItem.id"></commonList>
           </div>

         </div>

         <div class="right right_lg" v-else-if="currentItem.template == 'cert_query' && leftData[0].children.length==0">
           <div class="box" >
             <div class="title flex lg">
               <div class="flex_item"><b class="">{{currentItem.name}}</b></div>
               <div class="bread">
                 <a href="#">首页</a> > <a href="#" v-if="leftData.length>0">{{leftData[0].name}}</a>
               </div>
             </div>
             <certQuery :column-id="columnId" :pid="pid" :id="currentItem.id"></certQuery>
           </div>

         </div>


         <div class="right" :class="{right_lg:columnId==pid && leftData.length > 0 && leftData[0].children.length==0}" v-else>
           <div class="box">
             <div class="title flex lg" v-if="currentItem">
               <div class="flex_item"><b class="">{{currentItem.name}}</b></div>
               <div class="bread">
                 <a href="/">首页</a> > <a href="#" v-if="leftData.length>0">{{leftData[0].name}}</a> > <span>{{currentItem.name}}</span>
               </div>
             </div>
             <div class="newsitem" >
               <channel :id="currentItem.id" v-if="currentItem.template == 'channel'"></channel>
               <develop  v-if="currentItem.template == 'develop'"></develop>
             </div>
             <traningPlan :column-id="columnId" :pid="pid" :id="currentItem.id" :column="currentItem" v-if="currentItem.name=='培训计划'"></traningPlan>
             <certificationApplication  v-else-if="currentItem.name == '认证申请'"></certificationApplication>
             <trainingApplication :preData="plans"  v-else-if="currentItem.name == '培训申请'"></trainingApplication>
             <survey :column-id="columnId" :pid="pid" v-else-if="currentItem.name == '客户调查列表'"></survey>
             <message  v-else-if="currentItem.name == '客户留言'"></message>
             <feedback  v-else-if="currentItem.name == '意见、建议反馈'"></feedback>
             <dateList :column-id="columnId" :pid="pid" :id="currentItem.id" v-else-if="currentItem.template == 'date_list'"></dateList>
             <pictureList :column-id="columnId" :pid="pid" :id="currentItem.id" v-else-if="currentItem.template == 'picture_list'"></pictureList>
             <commonList :column-id="columnId" :pid="pid" :id="currentItem.id" :column="currentItem" v-else-if="currentItem.template == 'common_list'"></commonList>
             <pictureNews :column-id="columnId" :pid="pid" :id="currentItem.id" v-else-if="currentItem.template == 'picture'"></pictureNews>

           </div>
         </div>
       </template>
        <template v-else>
          <div class="right right_lg" >
            <div class="box" >
              <div class="title flex lg">
                <div class="flex_item"><b class="">证书查询</b></div>
                <div class="bread">
                  <a href="">首页</a> > <a href="">证书查询</a>
                </div>
              </div>
              <certQuery ref="cert" :column-id="columnId" :query="queryParam" :pid="pid" :id="currentItem.id"></certQuery>
            </div>

          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import traningPlan from "@/components/traningPlan";
import certification from "@/components/certification";
import zl_certification from "@/components/zlCertification";
import channel from "@/components/channel";
import develop from "@/components/develop";
import dateList from "@/components/dateList";
import pictureList from "@/components/pictureList";
import commonList from "@/components/commonList";
import certificationApplication from "@/components/certificationApplication";
import trainingApplication from "@/components/trainingApplication";
import pictureColumn from "@/components/pictureColumn";
import survey from "@/components/survey";
import message from "@/components/message";
import feedback from "@/components/feedback";
import pictureNews from "@/components/pictureNews";
import certQuery from "@/components/certQuery";
import certAdv from "@/components/certAdv";

import {mapMutations,mapState} from "vuex";

export default {
  name:"channels",
  components:{
    certification,
    zl_certification,
    channel,
    develop,
    dateList,
    pictureList,
    commonList,
    certificationApplication,
    trainingApplication,
    pictureColumn,
    survey,
    message,
    feedback,
    pictureNews,
    certQuery,
    traningPlan,
    certAdv
  },
  data() {
    return {
      columnId:0,
      pid:0,
      leftData:[],
      columnPid:0,
      currentItem:{},
      showAnnounce:false,
      queryParam:{},
      planId:0,
      plans:{}
    }
  },
  mounted() {
    $("#left").hide();
    $("#right").hide();
    this.setShowTips(false)
    this.columnId = this.$route.params.id.slice(0,-5);
    this.pid = this.$route.params.pid;
    this.planId = this.$route.query.planId || 0;

    if(this.planId > 0){
      this.getPlanDetail()
    }
    //设置索引值
    let index = this.columnData.findIndex(item=>item.id == this.pid)
    if(index != -1){
      this.setTabIndex(index + 1);
    }
    console.log(this.pid);
    this.getAllColumn();
  },
  methods:{
    ...mapMutations(['setShowTips','setTabIndex']),
    async getPlanDetail(){
      let res = await this.$api.getArticleData({
        id:this.planId
      });
      console.log(res,'====');
      this.plans = res.data.data
    },
    async getAllColumn(){
      let res = await this.$api.getAllColumn({
        pid:this.pid,
        id:this.columnId
      });
      this.leftData = res.data.data.list;
      this.columnPid = res.data.data.pid.parentId
      console.log("栏目父id",this.columnPid);
      //通过栏目确定当前选中的栏目
      if(this.leftData[0]?.children.length > 0){
        if(this.columnId == this.pid){
          if(this.leftData[0].template == 'picture_column'){
            //如果是图片栏目
            this.currentItem = this.leftData[0];
          }else if(this.leftData[0]?.children[0]?.children.length > 0){
            this.currentItem = this.leftData[0].children[0].children[0]
            console.log(0)
          }else{
            this.currentItem = this.leftData[0]?.children[0];
            console.log(1)
          }
        }else if(this.columnPid == this.pid){
          let index = this.leftData[0]?.children.findIndex(item=>item.id == this.columnId);
          this.currentItem = this.leftData[0]?.children[index]?.children[0]
          if(this.leftData[0]?.children[index]?.children.length > 0){
            this.currentItem = this.leftData[0].children[index].children[0]
          }else{
            this.currentItem = this.leftData[0]?.children[index]
          }
        }else {
          console.log(3)
          this.currentItem = res.data.data.pid
        }
      }else{
        this.currentItem = this.leftData[0];
      }
      console.log(this.currentItem)
      console.log('当前选中的栏目为'+this.currentItem?.id)
      console.log('当前选中的栏目为'+this.currentItem?.name)
      if(this.$store.state.stations.id == '1004' && this.currentItem?.name =='证书公告'){
        this.showAnnounce = true
      }
    },
    navTo(item){
      this.$router.push(`/channels/${this.pid}/${item.id}.html`)
    },
    queryClick(e){
      this.showAnnounce = true;
      //查询通知公告内容
      this.queryParam = e
      this.$nextTick(function (){
        this.$refs.cert.getList()
      })
    },
  },
  computed:{
    ...mapState(['columnData']),
    bannerUrl(){
      if(this.leftData.length > 0 && this.leftData[0]?.imgUrl){
        return this.$baseURL + this.leftData[0]?.imgUrl
      }
      return '/images/1_02.jpg'
    }
  }
}
</script>

<style scoped>
.mb{
  margin-bottom: 20px;
}
.mt{
  margin-top: 20px;
}
</style>
