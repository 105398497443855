<template>
  <div class="bd">
    <ul class="newslist">
      <li v-for="(item,index) in dataList" :key="index" @click="toDetail(item.id)">
        <div class="flex_item"><a href="javascript:;">{{item.title}}</a></div>
        <span>{{parseTime(column.name,item.publishTime)}}</span>
      </li>
    </ul>

    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
//日期列表模型组件

import {parseTime} from "@/libs/utils";

export default {
  props:{
    id:{
      type:[String,Number],
      default:0
    },
    columnId:{
      type:[String,Number],
      default:0
    },
    pid:{
      type:[String,Number],
      default:0
    },
    column:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  filters:{
    format(x){
      return x.split(" ")[0]
    }
  },
  data(){
    return {
      dataList:[],
      pageNum: 1,
      pageSize: 20,
      total: 0,
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    parseTime,
    async getList(){
      let res = await this.$api.getArticleListById({
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        id:this.id
      })
      this.dataList = res.data.data.list
      this.total = res.data.data.total
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    getText(str){
      let res = str.replace(/[^\u4e00-\u9fa5]/gi, "")
      if(res.length > 80){
        res = res.slice(0,80) + '...'
      }
      return res
    },
    toDetail(id){
      this.$router.push(`/contents/${this.pid}/${this.columnId}/${id}.html`)
    }
  },
}
</script>

<style scoped>
ul{
  margin-bottom: 30px;
}
</style>
