export const parseTime = (columnName,date)=>{
    if(columnName == '培训计划'){
        let listDate = new Date(date);
        let now = new Date();
        if(now.getTime() - listDate.getTime() > 0){
            return '已结束';
        }
    }
    return date.split(" ")[0];
}
