<template>
  <div>
    <div class="title flex lg">
      <div class="flex_item"><b class="">{{columns.name}}</b></div>
      <div class="bread">
        <a href="#">首页</a> > <a href="#">{{columns.name}}</a>
      </div>
    </div>
    <div class="newsitem">
      <div class="pic_item" v-for="(item,index) in columns.children" :key="item.id" @click="toDetail(item.id)">
        <div class="item-box">
          <a><img  :src="$baseURL + item.imgUrl" /></a>
          <a><div class="pic_title">{{item.name}}</div></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  props:{
    columns:[],
    pid:{
      type:[String,Number],
      default:0
    }
  },
  data(){
    return{
    }
  },
  mounted(){
    console.log(this.columns)
  },
  methods:{
    toDetail(id){
      this.$router.push(`/channels/${this.pid}/${id}.html`)
    }
  },
}
</script>
<style scoped>
.pic_item{
  width: 33.3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.newsitem{
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}
.item-box{
  width: 317px;
  height: 257px;
}
.pic_title{
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
}
</style>
