<template>
  <div class="form">
    <table width="100%">
      <tr>
        <td width="85"><span class="redstar">*</span>姓名：</td>
        <td>
          <input type="text" class="input" v-model="comment.name" />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>邮箱：</td>
        <td >
          <input type="text" class="input" v-model="comment.email" />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>移动电话：</td>
        <td >
          <input
              type="text"
              class="input"
              v-model="comment.telephone"
          />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>主题：</td>
        <td >
          <input type="text" class="input" v-model="comment.title" />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>内容：</td>
        <td>
                    <textarea
                        style="border: 1px solid #d8eaff; background: #f5f5f6"
                        cols="60"
                        rows="10"
                        v-model="comment.content"
                    ></textarea>
        </td>
      </tr>
      <tr>
        <td>
          <button type="button" class="btn" @click="send">
            发送
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

export default({
  name: "message",
  data(){
    return{
      comment: {
        name: "",
        telephone: "",
        email: "",
        title: "",
        content: "",
      },
    }
  },
  methods:{
    async send() {
      let res = await this.$api.create(this.comment);
      if (res.data.code == 200) {
        this.$message({
          message: "留言成功",
          type: "success",
        });
        for (let item in this.comment) {
          this.comment[item] = "";
        }
      } else {
        this.$message.error(res.data.msg);
      }
    },
  }
})
</script>

<style scoped>

</style>
