<template>
    <div class="news-text">
      <ul >
        <li
            v-for="(item, index) in dataList"
            :key="index"
            class="nli"
        >
          <div  @click="toDetail(item.id)">
            <div class="news-date">
              <b>{{item.publishTime.split(" ")[0].split("-")[2]}}</b>
              <p>{{item.publishTime.split(" ")[0].split("-")[0]}}-{{item.publishTime.split(" ")[0].split("-")[1]}}</p>
            </div>
            <h2>
              {{item.title}}
            </h2>
            <p class="news-content">
              {{getText(item.content)}}
            </p>
          </div>
        </li>
      </ul>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
</template>

<script>
//日期列表模型组件


export default {
  props:{
    id:{
      type:[String,Number],
      default:0
    },
    columnId:{
      type:[String,Number],
      default:0
    },
    pid:{
      type:[String,Number],
      default:0
    }
  },
  filters:{
    format(x){
      return x.split(" ")[0]
    }
  },
  data(){
    return {
      dataList:[],
      pageNum: 1,
      pageSize: 8,
      total: 0,
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    async getList(){
      let res = await this.$api.getArticleListById({
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        id:this.id
      })
      this.dataList = res.data.data.list
      this.total = res.data.data.total
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    getText(str){
      if(!str){
        return ""
      }
      let res = str.replace(/[^\u4e00-\u9fa5]/gi, "").replace("微软雅黑","")
      if(res.length > 80){
        res = res.slice(0,80) + '...'
      }
      return res
    },
    toDetail(id){
      this.$router.push(`/contents/${this.pid}/${this.columnId}/${id}.html`)
    }
  },
}
</script>

<style scoped>
.news-date {
  width: 72px;
  height: 72px;
  border: 1px solid #bfd4e9;
  float: left;
  margin-right: 30px;
  font-family: arial;
  color: #6198e2;
  background: #fff;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.news-date b {
  height: 43px;
  line-height: 42px;
  font-size: 26px;
}
.news-date p {
  font-size: 14px;
  margin: 0 5px;
  height: 26px;
  line-height: 26px;
  color: #6198e2;
  background: #fff;
  border-top: 1px solid #dfe7f3;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.news-content {
  width: 100%;
  height: 45px;
  color: #666;
  line-height: 24px
}

.news-text {
  margin-bottom: 8px;
  margin-top: 25px;
  margin-left: 10px;
}
.news-text li {
  margin-top: 25px;
}
.news-text li h2 {
  overflow: hidden;
  font-size: 16px;
  color: #333;
  font-weight: 700;
  height: 22px;
  line-height: 22px;
  margin-bottom: 8px;
  margin-top: 0;
}
.news-text li:hover a {
  color: #275caa;
}
.news-text li:hover .news-date {
  border: 1px solid #275caa;
  background: #275caa;
  color: #fff;
}
.news-text li:hover .news-date p {
  color: #fff;
  background: #275caa;
}
.nli {
  margin: 35px 0 !important;
  border-bottom: 1px solid #c5c5c5 !important;
  padding-bottom: 30px;
}
.news-text li:hover h2 {
  color: #275caa;
}
h2{
  cursor: pointer;
}
</style>
