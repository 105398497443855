<template>
    <div class="news-text">
      <ul >
        <li
            v-for="(item, index) in dataList"
            :key="index"
            class="nli"
            @click="toDetail(item.id)"
        >
        <div class="img" v-if="item.imgUrl">
          <img :src="$baseURL + item.imgUrl" alt="">
        </div>
          <div class="img" v-else>
            <img src="/images/placeholder.png" alt="">
          </div>
          <h2 class="title2">
            {{item.title}}
          </h2>
          <div class="content">
            {{getText(item.content)}}
          </div>
          <span class="date">
            {{item.createTime.split(" ")[0]}}
          </span>
        </li>
      </ul>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
</template>

<script>
//日期列表模型组件


export default {
  props:{
    id:{
      type:[String,Number],
      default:0
    },
    columnId:{
      type:[String,Number],
      default:0
    },
    pid:{
      type:[String,Number],
      default:0
    }
  },
  data(){
    return {
      dataList:[],
      pageNum: 1,
      pageSize: 8,
      total: 0,
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    async getList(){
      let res = await this.$api.getArticleListById({
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        id:this.id
      })
      this.dataList = res.data.data.list
      this.total = res.data.data.total
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    getText(str){
      let res = str.replace(/[^\u4e00-\u9fa5]/gi, "")
      if(res.length > 80){
        res = res.slice(0,80) + '...'
      }
      return res
    },
    toDetail(id){
      this.$router.push(`/contents/${this.pid}/${this.columnId}/${id}.html`)
    }
  },
}
</script>

<style scoped>
.nli{
  margin: 30px 18px 0;
  overflow: hidden;
  border-bottom: 1px solid #dededf;
  padding-bottom: 30px;
}
.img{
  width: 200px;
  height: 133px;
  float: left;
  margin-right: 30px;
  background: #fff;
}
.img img{
  margin: 0 auto;
  width: 200px;
  height: 133px;
  border: 0;
  display: block;
}
.title2{
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  font-size: 16px;
  color: #333;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 5px;
}
.date{
  color: #999;
  display: block;
  padding: 10px 0 0;
}
.content{
  height: 40px;
  overflow: hidden;
}
ul{
  margin-bottom: 30px;
}
.news-text li:hover h2 {
  color: #275caa;
}
h2{
  cursor: pointer;
}
</style>
