<template>
  <div class="form">
    <table width="100%">
      <tr>
        <td width="85"><span class="redstar">*</span>单位名称：</td>
        <td>
          <input
              type="text"
              class="input"
              v-model="certTwo.businessName"
          />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>传真：</td>
        <td>
          <input type="text" class="input" v-model="certTwo.fax" />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>培训开始时间：</td>
        <!--                  <td><input type="text" class="input" v-model="certTwo.beginTime"></td>-->
        <td>
          <el-date-picker
              v-model="certTwo.beginTime"
              type="datetime"
              placeholder="培训开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>培训结束时间：</td>
        <!--                  <td><input type="text" class="input" v-model="certTwo.endTime"></td>-->
        <td>
          <el-date-picker
              v-model="certTwo.endTime"
              type="datetime"
              placeholder="培训结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>联系人：</td>
        <td>
          <input
              type="text"
              class="input lg"
              v-model="certTwo.contact"
          />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>联系电话：</td>
        <td>
          <input
              type="text"
              class="input"
              v-model="certTwo.telephone"
          />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>申请时间：</td>
        <!--                  <td><input type="text" class="input lg" v-model="certTwo.appleTime"></td>-->
        <td>
          <el-date-picker
              v-model="certTwo.appleTime"
              type="datetime"
              placeholder="选择申请时间"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </td>
      </tr>
      <tr>
        <td><span>&nbsp;&nbsp;&nbsp;</span>其他信息：</td>
        <td>
          <input
              type="text"
              class="input lg"
              v-model="certTwo.digest"
          />
        </td>
      </tr>
      <tr>
        <td valign="top">
          <span class="redstar">*</span>参加培训内容：
        </td>
        <td>
          <ul class="clearfix">
            <template v-if="Object.keys(preData).length == 0">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox :label="item.name" v-for="item in certification" :key="item.id"></el-checkbox>
                </el-checkbox-group>
            </template>
            <li v-else style="width: 100%">
              <label>
                {{preData.title}}
              </label>
            </li>
          </ul>
          <button type="button" class="btn" @click="send">
            发送
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
//认证申请


export default {
  props:{
    preData:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return {
      certification: [],
      checkList:[],
      certOne: {
        name: "",
        telephone: "",
        businessName: "",
        businessNumber: "",
        email: "",
        businessSites: "",
        businessAddress: "",
        postCode: "",
        scope: "",
        digest: "",
        object: "",
      },
      types: [
        { name: "QMS", checked: false },
        { name: "EMS/OHSMS", checked: false },
        { name: "QMS/EMS/OHSMS", checked: false },
      ],
      certTwo: {
        businessName: "",
        fax: "",
        beginTime: "",
        endTime: "",
        cultivate: "",
        contact: "",
        telephone: "",
        digest: "",
        appleTime: "",
      },
    }
  },
  watch:{
    preData(newv){
      this.certTwo.beginTime = newv.startTime;
      this.certTwo.endTime = newv.endTime;
      console.log(this.certTwo)
    }
  },
  created() {
    this.getProject()
  },
  methods:{
    async getProject(){
      let res = await this.$api.getCertProject({});
      this.certification = res.data.data;
      this.certification.forEach((item) => {
        item.checked = false;
      });
    },
    async certificationObject() {
      let res = await this.$api.certificationObject();
      this.certification = res.data.data;
      this.certification.forEach((item) => {
        item.checked = false;
      });
    },
    async send() {
      if(!this.certTwo.businessName){
        return this.$message.error("请输入单位名称");
      }
      if(!this.certTwo.fax){
        return this.$message.error("请输入传真");
      }
      if(!this.certTwo.fax){
        return this.$message.error("请选择开始时间");
      }
      if(!this.certTwo.fax){
        return this.$message.error("请选择结束时间");
      }
      if(!this.certTwo.contact){
        return this.$message.error("请输入联系人");
      }
      if(!this.certTwo.telephone){
        return this.$message.error("请输入联系电话");
      }

      //培训申请
      if(Object.keys(this.preData).length == 0){
        this.certTwo.cultivate = this.checkList.join(',')
      }else{
        this.certTwo.cultivate = this.preData.title
      }
      if(!this.certTwo.cultivate){
        return this.$message.error("请选择参加的培训内容");
      }
      //认证申请
      let res = await this.$api.submitCultivate(this.certTwo);
      if (res.data.code == 200) {
        this.$message({
          message: "申请成功",
          type: "success",
        });
        for (let item in this.certTwo) {
          this.certTwo[item] = "";
        }
        this.checkList = []
      } else {
        this.$message.error(res.data.msg);
      }
    },
  },
}
</script>

<style scoped>
/deep/ .el-input__inner {
  background: #f5f5f6 !important;
  border: 1px solid #d8eaff !important;
  height: 34px;
  border-radius: 34px;
}
/deep/.el-input__icon {
  line-height: 34px;
}
</style>
