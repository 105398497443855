<template>
  <div class="bd">
<!--    中机科展示-->
    <ul class="newslist" v-if="stations.id == 1004">
    <!--  展示表格-->
      <el-table
          :data="tableData"
          border
          style="width: 100%">
        <el-table-column
            prop="certNo"
            label="证书编号"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="name"
            label="企业名称"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="productModel"
            align="center"
            label="产品型号">
        </el-table-column>
        <el-table-column
            prop="productName"
            align="center"
            label="产品名称">
        </el-table-column>
        <el-table-column
            prop="parameter"
            align="center"
            label="主要参数">
        </el-table-column>

        <el-table-column
            prop="issueDate"
            align="center"
            label="发证日期">
        </el-table-column>

        <template >
          <el-table-column
              align="center"
              label="证书">
            <template slot-scope="scope">
              <el-col :span="12" v-if="scope.row.frontImage">
                <el-image
                    style="width: 40px; height: 40px"
                    :src="$baseURL + scope.row.frontImage"
                    :preview-src-list="[$baseURL+scope.row.frontImage]">
                </el-image>
              </el-col>
              <el-col :span="12" v-else>
                <el-image
                    style="width: 40px; height: 40px"
                    src="/images/white.jpg"
                    :preview-src-list="['/images/white.jpg']">
                </el-image>
              </el-col>

              <el-col :span="12" v-if="scope.row.backImage">
                <el-image
                    style="width: 40px; height: 40px"
                    :src="$baseURL + scope.row.backImage"
                    :preview-src-list="[$baseURL + scope.row.backImage]">
                </el-image>
              </el-col>

              <el-col :span="12" v-else>
                <el-image
                    style="width: 40px; height: 40px"
                    src="/images/white.jpg"
                    :preview-src-list="['/images/white.jpg']">
                </el-image>
              </el-col>
            </template>
          </el-table-column>

        </template>

      </el-table>
    </ul>

    <ul class="newslist" v-if="stations.id == 1005010">
      <el-table
          :data="tableData"
          border
          style="width: 100%">
        <el-table-column
            prop="projcertcode"
            label="证书编号"
            align="center"
            >
        </el-table-column>
        <el-table-column
            prop="corpnamechin"
            label="企业名称"
            align="center"
            >
        </el-table-column>
        <el-table-column
            prop="provdate"
            align="center"
            label="获证日期">
        </el-table-column>
        <el-table-column
            prop="dealdate"
            align="center"
            label="证书到期日">
        </el-table-column>
        <el-table-column
            prop="typeName"
            align="center"
            label="体系类型">
        </el-table-column>

        <el-table-column
            prop="certstate"
            align="center"
            label="证书状态">
        </el-table-column>
        <el-table-column
            prop="certmark"
            align="center"
            label="状态">
        </el-table-column>

        <el-table-column
            prop="fanwei"
            align="center"
            label="证书范围">
        </el-table-column>

        <el-table-column
            prop="dizhi"
            align="center"
            label="企业地址">
        </el-table-column>
        <el-table-column
            prop="chandate"
            align="center"
            label="变更日期">
        </el-table-column>


      </el-table>
    </ul>

    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
//证书公告模型组件

import {mapState} from 'vuex'

export default {
  props:{
    query:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  created() {
    if(this.$store.state.stations.id == '1004'){
      //发送请求
      this.getList(1)
    }
  },
  data(){
    return {
      tableData:[],
      pageNum: 1,
      pageSize: 10,
      total: 0,
    }
  },
  computed:{
    ...mapState(['stations'])
  },
  methods:{
    async getList(type = 0){
      let res = await this.$api.getNotice({
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        ...this.query,
        type
      })
      if(res.data.code === 200){
        this.tableData = res.data.data.list
        this.total = res.data.data.total
      }else{
        await this.$msgbox(res.data.msg, '提示')
      }

    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList(1);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList(1);
    },
    getText(str){
      let res = str.replace(/[^\u4e00-\u9fa5]/gi, "")
      if(res.length > 80){
        res = res.slice(0,80) + '...'
      }
      return res
    },
    toDetail(id){
      this.$router.push(`/contents/${this.pid}/${this.columnId}/${id}.html`)
    }
  },
}
</script>

<style scoped>
ul{
  margin-bottom: 30px;
}
</style>
