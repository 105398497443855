<template>
  <div class="form">
    <table width="100%">
      <tr>
        <td width="85"><span class="redstar">*</span>申请人：</td>
        <td>
          <input type="text" class="input" v-model="certOne.name" />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>联系电话：</td>
        <td>
          <input
              type="text"
              class="input"
              v-model="certOne.telephone"
          />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>企业名称：</td>
        <td>
          <input
              type="text"
              class="input lg"
              v-model="certOne.businessName"
          />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>企业人数：</td>
        <td>
          <input
              type="text"
              class="input"
              v-model="certOne.businessNumber"
          />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>电子邮箱：</td>
        <td>
          <input
              type="text"
              class="input md"
              v-model="certOne.email"
          />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>企业网站：</td>
        <td>
          <input
              type="text"
              class="input lg"
              v-model="certOne.businessSites"
          />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>公司地址：</td>
        <td>
          <input
              type="text"
              class="input lg"
              v-model="certOne.businessAddress"
          />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>邮政编码：</td>
        <td>
          <input
              type="text"
              class="input"
              v-model="certOne.postCode"
          />
        </td>
      </tr>
      <tr>
        <td><span class="redstar">*</span>认证范围：</td>
        <td>
          <input type="text" class="input" v-model="certOne.scope" />
        </td>
      </tr>
      <tr>
        <td><span>&nbsp;&nbsp;&nbsp;</span>其他信息：</td>
        <td>
          <input
              type="text"
              class="input lg"
              v-model="certOne.digest"
          />
        </td>
      </tr>
      <tr>
        <td valign="top"><span class="redstar">*</span>认证项目：</td>
        <td>
          <ul class="clearfix">
            <el-checkbox-group v-model="checkList">
              <el-checkbox :label="item.name" v-for="item in certification" :key="item.id"></el-checkbox>
            </el-checkbox-group>
          </ul>
          <button type="button" class="btn" @click="send">
            发送
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
//认证申请


export default {
  props:{
  },
  data(){
    return {
      certification: [],
      checkList:[],
      certOne: {
        name: "",
        telephone: "",
        businessName: "",
        businessNumber: "",
        email: "",
        businessSites: "",
        businessAddress: "",
        postCode: "",
        scope: "",
        digest: "",
        object: "",
      },
      types: [
        { name: "QMS", checked: false },
        { name: "EMS/OHSMS", checked: false },
        { name: "QMS/EMS/OHSMS", checked: false },
      ],
    }
  },
  mounted() {
    this.getProject()
  },
  methods:{
    async certificationObject() {
      let res = await this.$api.certificationObject();
      this.certification = res.data.data;
      this.certification.forEach((item) => {
        item.checked = false;
      });
    },
    async getProject(){
      let res = await this.$api.getCertProject({});
      this.certification = res.data.data;
      this.certification.forEach((item) => {
        item.checked = false;
      });
    },
    async send() {
      this.certOne.object = this.checkList.join(",");
      if(!this.certOne.name){
        return this.$message.error("请输入申请人");
      }
      if(!this.certOne.telephone){
        return this.$message.error("请输入联系电话");
      }
      if(!this.certOne.businessName){
        return this.$message.error("请输入企业名称");
      }
      if(!this.certOne.businessNumber){
        return this.$message.error("请输入企业人数");
      }
      if(!this.certOne.email){
        return this.$message.error("请输入电子邮箱");
      }
      if(!this.certOne.businessSites){
        return this.$message.error("请输入企业网站");
      }
      if(!this.certOne.businessAddress){
        return this.$message.error("请输入公司地址");
      }
      if(!this.certOne.postCode){
        return this.$message.error("请输入邮政编码");
      }
      if(!this.certOne.scope){
        return this.$message.error("请输入认证范围");
      }
      if(!this.certOne.object){
        return this.$message.error("请选择认证项目");
      }
      //认证申请
      let res = await this.$api.submitCertification(this.certOne);
      if (res.data.code == 200) {
        this.$message({
          message: "申请成功",
          type: "success",
        });
        for (let item in this.certOne) {
          this.certOne[item] = "";
        }
        this.checkList = []
      } else {
        this.$message.error(res.data.msg);
      }
    },
  },
}
</script>

<style scoped>
/deep/ .el-input__inner {
  background: #f5f5f6 !important;
  border: 1px solid #d8eaff !important;
  height: 34px;
  border-radius: 34px;
}
/deep/.el-input__icon {
  line-height: 34px;
}
</style>
