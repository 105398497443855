<template>
  <div>
    <div v-html="articleDetail.content || ''">
    </div>
    <div v-if="articleDetail.contentFileUrl && JSON.parse(articleDetail.contentFileUrl).length>0">
      <ul>
        <li v-for="(item,index) in JSON.parse(articleDetail.contentFileUrl)" :key="index" style="margin-bottom: 10px"><a :href="item.url" style="color: #2d52a0">
          <img src="/images/fujian.png" alt="" style="width: 20px">
          {{item.name}}
        </a></li>
      </ul>
    </div>

  </div>
</template>

<script>
//单页模型组件

export default {
  props:{
    id:{
      type:Number,
      default:0
    },
  },
  data(){
    return {
      articleDetail:'',
      name:'',
    }
  },
  mounted() {
    this.getArticleData(this.id)
  },
  methods:{
    async getArticleData(id){
      let res = await this.$api.getArticleById({id:id});
      this.articleDetail = res.data.data;
      console.log(res.data.data,'--')
    },
    toDetail(id,name){
      this.$router.push({path:'/view',query:{articleId:id,name:name}})
    },
  },
}
</script>

<style scoped>

</style>
