<template>
  <div class="bd">
    <ul class="newslist">
      <li v-for="(item,index) in dataList" :key="index" @click="toDetail(item.id)">
        <div class="flex_item"><a href="javascript:;">{{item.title}}</a></div>
        <span>{{parseTime('证书公告',item.publishTime)}}</span>
      </li>
    </ul>

    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>

import {parseTime} from "@/libs/utils";

export default {
  name: "certAdv",
  filters:{
    format(x){
      return x.split(" ")[0]
    }
  },
  data(){
    return {
      dataList:[],
      pageNum: 1,
      pageSize: 20,
      total: 0,
      columnId:0
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    parseTime,
    async getList(){
      let res = await this.$api.getDataByName({
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        columnName:'证书公告'
      })
      console.log(res,'=======')
      this.dataList = res.data.data.list
      this.total = res.data.data.total
      this.columnId = res.data.data.columnId
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    toDetail(id){
      this.$router.push(`/contents/${this.columnId}/${this.columnId}/${id}.html`)
    }
  },
}
</script>

<style scoped>
ul{
  margin-bottom: 30px;
}
</style>
