<template>
  <div>
    <div class="form">
      <table width="100%">
        <tr>
          <td width="85"><span class="redstar">*</span>姓名：</td>
          <td>
            <input type="text" class="input" v-model="comment.name" />
          </td>
        </tr>
        <tr>
          <td><span class="redstar">*</span>邮箱：</td>
          <td>
            <input type="text" class="input" v-model="comment.email" />
          </td>
        </tr>
        <tr>
          <td><span class="redstar">*</span>移动电话：</td>
          <td>
            <input
                type="number"
                class="input"
                v-model="comment.telephone"
                @blur="blurTest"
            />
            <span style="color: red">{{blurTest() ? '': '* 手机号格式不正确'}}</span>
          </td>
        </tr>
        <!-- <tr>
          <td><span class="redstar">*</span>主题：</td>
          <td>
            <input type="text" class="input" v-model="comment.title" />
          </td>
        </tr> -->
        <tr>
          <td><span class="redstar">*</span>内容：</td>
          <td>
                    <textarea
                        style="border: 1px solid #d8eaff; background: #f5f5f6"
                        cols="60"
                        rows="10"
                        v-model="comment.content"
                    ></textarea>
          </td>
        </tr>
        <tr>
          <td>
            <button type="button" class="btn" @click="send">
              发送
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

export default ({
  name: "feedback",
  data() {
    return {
      comment:{
        "name": "",
        "telephone": "",
        "email": "",
        // "title": "",
        "content": ""
      }
    }
  },
  methods:{
    async send(){
      if(!this.comment.name){
         return  this.$message.error('请先输入姓名');
      }
      if(!this.comment.email){
        return this.$message.error('请先输入邮箱');
      }
      if(!/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(this.comment.email)){
        return this.$message.error('邮箱格式不正确');
      }
      if(!this.comment.telephone){
        return this.$message.error('请先输入手机号码');
      }
      if( !/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(this.comment.telephone)){
        return this.$message.error('手机号码格式不正确');
      }
      let res = await this.$api.create(this.comment);
      if(res.data.code == 200){
        this.$message({
          message: '留言成功',
          type: 'success'
        });
        for(let item in this.comment){
          this.comment[item] = ""
        }
      }else{
        this.$message.error(res.data.msg);
      }
    },
    blurTest(){
      if(!this.comment.telephone){
        return true;
      }
      return /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(this.comment.telephone)
    }
  }
})
</script>

<style scoped>

</style>
